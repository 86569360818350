<template>
  <b-row>

    <h2 class="pl-1">{{ $t('side_bar.country') }}</h2>

    <!--  BEFORE TABLE  -->
    <div class="d-flex justify-content-between col-12">
      <b-col
        md="4"
        class="my-1 px-0"
      >
        <b-form-group
          class="mb-0"
        >
          <b-input-group size="md">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              :placeholder="$t('reusable.search_placeholder')"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                {{ $t('reusable.clear_btn') }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <div
        class="my-1 float-right"
      >
        <modal-button
          ripple="rgba(113, 102, 240, 0.15)"
          size="lg"
          name="modal-main"
          :modalButton="$t('reusable.modal_add_btn')"
          @btn-emit="createNewCountry"
          @open-emit="resetInputs"
          :modal-title="$t('region_table.country_modal_title')"
          variant="success"
        >
          <template v-slot:button @click="createNewCountry">{{ $t('reusable.modal_add_btn') }}</template>

          <template v-slot:modal-body>
            <div class="d-flex">

              <b-col cols="6">
                <b-form-group
                  :label="$t('region_table.country_modal_input_ru')"
                  label-for="defaultLabel"
                >
                  <b-form-input
                    v-model="name.ru"
                    id="defaultLabel"
                    size="lg"
                    :placeholder="$t('region_table.country_modal_input_ru')"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="6">
                <b-form-group
                  :label="$t('region_table.country_modal_input_uz')"
                  label-for="defaultLabel"
                >
                  <b-form-input
                    v-model="name.uz"
                    id="defaultLabel"
                    size="lg"
                    :placeholder="$t('region_table.country_modal_input_uz')"
                  />
                </b-form-group>
              </b-col>
            </div>
          </template>
        </modal-button>

      </div>
    </div>

    <!--  TABLE  -->
    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        sort-icon-left
        :busy="isBusy"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"

        @filtered="onFiltered"
      >
        <template v-slot:head(is_deleted)="data">
          <b-form-select
            size="md"
            v-model="status"
            class="w-100"
          >
            <template #first>
              <option
                selected
                :value="null"
              >
                Выберите статус
              </option>
            </template>
            <option
              v-for="(status, index) in statuses"
              :key="index"
              :value="status"
            >
              {{ status }}
            </option>
          </b-form-select>
        </template>

        <template #cell(is_deleted)="data">
          <b-badge :variant="changeVariant(data.item)">
            {{ data.item.is_deleted }}
          </b-badge>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-1">{{ $t('reusable.isBusy') }}</strong>
          </div>
        </template>

        <template #cell(name)="data">
          {{ checkLocales(data.item.name) }}
        </template>

        <template #cell(crud_row)="data">
          <div class="d-flex float-right">

            <b-dropdown
              no-caret
              id="table-action-dropdown"
              variant="link"
              size="md"
            >
              <template
                #button-content
                class="p-0"
              >
                <feather-icon
                  icon="MoreVerticalIcon"
                  class="p-0"
                />
              </template>

              <!--   PREVIEW BUTTON   -->
              <b-dropdown-item
                class="px-0"
              >
                <modal-button
                  ripple="rgba(113, 102, 240, 0.15)"
                  size="xl"
                  :name="`modal-preview-${data.item.id}`"
                  :backdrop-close="true"
                  :hide-footer="true"
                  :modalButton="$t('region_table.country_edit_modal_title')"
                  :id="data.item.id"
                  :modal-title="$t('reusable.preview_btn')"
                  :disableButton="!editAllow"
                  variant="flat-primary"
                  @open-emit="getCountryById(data.item.id)"
                  @btn-emit="updateCountry(data.item.id, data.item)"
                >
                  <template
                    v-slot:button
                    style="width: 100% !important;"
                  >
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="EyeIcon"
                        size="21"
                        style="margin-right: 6px"
                      />
                      <span>{{ $t('reusable.preview_btn') }}</span>
                    </div>
                  </template>

                  <template v-slot:modal-body>
                    <div class="d-flex flex-wrap">

                      <b-col cols="6">
                        <b-form-group
                          :label="$t('region_table.country_modal_input_ru')"
                          label-for="defaultLabel"
                        >
                          <b-form-input
                            v-model="data.item.name.ru"
                            id="defaultLabel"
                            size="lg"
                            :disabled="!editAllow"
                            :placeholder="$t('region_table.country_modal_input_ru')"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="6">
                        <b-form-group
                          :label="$t('region_table.country_modal_input_uz')"
                          label-for="defaultLabel"
                        >
                          <b-form-input
                            v-model="data.item.name.uz"
                            id="defaultLabel"
                            size="lg"
                            :disabled="!editAllow"
                            :placeholder="$t('region_table.country_modal_input_uz')"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="6">
                        <b-form-group
                          :label="$t('region_table.created_at')"
                          label-for="defaultLabel"
                        >
                          <b-form-input
                            v-model="createdAt"
                            id="defaultLabel"
                            size="lg"
                            :disabled="!editAllow"
                            :placeholder="$t('region_table.created_at')"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="6">
                        <b-form-group
                          :label="$t('region_table.updated_at')"
                          label-for="defaultLabel"
                        >
                          <b-form-input
                            v-model="updatedAt"
                            id="defaultLabel"
                            size="lg"
                            :disabled="!editAllow"
                            :placeholder="$t('region_table.updated_at')"
                          />
                        </b-form-group>
                      </b-col>

                    </div>
                  </template>


                </modal-button>
              </b-dropdown-item>

              <!--  EDIT BUTTON  -->
              <b-dropdown-item
                class="px-0"
              >
                <modal-button
                  ripple="rgba(113, 102, 240, 0.15)"
                  size="xl"
                  :name="`modal-edit-${data.item.id}`"
                  :modalButton="$t('reusable.save_btn')"
                  :id="data.item.id"
                  :modal-title="$t('reusable.modal_edit_btn')"
                  variant="flat-warning"
                  @btn-emit="updateCountry(data.item.id, data.item)"
                >
                  <template
                    v-slot:button
                    style="width: 100% !important;"
                  >
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="Edit2Icon"
                        size="21"
                        style="margin-right: 6px"
                      />
                      <span>{{ $t('reusable.modal_edit_btn') }}</span>
                    </div>
                  </template>

                  <template v-slot:modal-body>
                    <div class="d-flex flex-wrap">

                      <b-col cols="6">
                        <b-form-group
                          :label="$t('region_table.country_modal_input_ru')"
                          label-for="defaultLabel"
                        >
                          <b-form-input
                            v-model="data.item.name.ru"
                            id="defaultLabel"
                            size="lg"
                            :placeholder="$t('region_table.country_modal_input_ru')"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="6">
                        <b-form-group
                          :label="$t('region_table.country_modal_input_uz')"
                          label-for="defaultLabel"
                        >
                          <b-form-input
                            v-model="data.item.name.uz"
                            id="defaultLabel"
                            size="lg"
                            :placeholder="$t('region_table.country_modal_input_uz')"
                          />
                        </b-form-group>
                      </b-col>

                    </div>
                  </template>

                </modal-button>
              </b-dropdown-item>

              <!--  DELETE BUTTON  -->
              <b-dropdown-item>
                <modal-button
                  ripple="rgba(113, 102, 240, 0.15)"
                  size="sm"
                  :name="`modal-delete-${data.item.id}`"
                  :modalButton="$t('region_table.country_delete_modal_title')"
                  :modal-title="$t('region_table.country_delete_modal_title')"
                  variant="flat-danger"
                  :id="data.item.id"
                  @btn-emit="deleteCountry"
                >
                  <template v-slot:button class="w-100">
                    <div class="d-flex align-items-center">
                      <feather-icon
                        icon="Trash2Icon"
                        size="21"
                        style="margin-right: 6px"
                      />
                      <span>{{ $t('reusable.modal_delete_btn') }}</span>
                    </div>
                  </template>

                  <template v-slot:modal-body>
                    <div class="my-2">
                      {{ $t('region_table.country_delete_modal_body') }}
                    </div>
                  </template>

                </modal-button>
              </b-dropdown-item>

            </b-dropdown>

          </div>
        </template>
      </b-table>
    </b-col>

    <!--  PAGINATION  -->
    <b-col
      cols="12"
    >
      <b-pagination
        v-if="showPagination"
        v-model="pagination.current"
        :total-rows="pagination.total"
        :per-page="pagination.per_page"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BSpinner
} from 'bootstrap-vue'
import ModalButton from '@/views/ui/modals/ModalButton.vue'
import api from '@/services/api'

export default {
  components: {
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BSpinner,
    ModalButton
  },
  data() {
    // const query = Object.assign({}, this.$route.query)

    return {
      createdAt: '',
      updatedAt: '',
      editAllow: false,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      status: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      statuses: [true, false],
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'name',
          label: this.$t('region_table.country_name'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$t('region_table.created_at'),
        },
        {
          key: 'updated_at',
          label: this.$t('region_table.updated_at'),
        },
        {
          key: 'crud_row',
          label: ' ',
        },
      ],
      variant: '',
      items: [],
      name: {
        ru: '',
        uz: '',
      },
      isBusy: false,
      pagination: {
        current: 1,
        total: 1,
        per_page: 5
      }
    }
  },
  watch: {
    '$route.query': {
      handler(query) {
        // const routeQueryPage = parseInt(query.page)
        this.pagination.current = query.page
        this.fetchCountryList()
      },
      deep: true,
      immediate: true
    },
    'pagination.current': {
      handler(page) {
        this.replaceRouter({
          ...this.query,
          page,
        })
      },
    }
  },

  computed: {
    rows() {
      return this.items.length
    },
    query() {
      return Object.assign({}, this.$route.query)
    },

    hasItems() {
      return this.items.length > 0
    },

    showPagination() {
      return this.hasItems && !this.isBusy
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key,
        }))
    },
  },

  async created() {
    await this.fetchCountryList()

  },

  methods: {

    resetInputs() {
      this.regionId = null
      this.name = {
        uz: null,
        ru: null
      }
    },

    replaceRouter(query) {
      this.$router.replace({ query })
        .catch(() => {
        })
    },

    async fetchCountryList() {
      this.isBusy = true
      const data = {
        page: this.$route.query.page
      }
      await api.regions.fetchCountry(data)
        .then(res => {
          this.items = res.data.data
          this.pagination.per_page = res.data.meta.per_page
          this.pagination.total = res.data.meta.total
          this.pagination.current = res.data.meta.current_page
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isBusy = false
        })
    },

    async getCountryById(id) {
      this.editAllow = false
      await api.regions.fetchOneCountry(id)
        .then(res => {
          this.createdAt = res.data.created_at
          this.updatedAt = res.data.updated_at
          this.name.ru = res.data.name.ru
          this.name.uz = res.data.name.uz
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {

        })
    },

    createNewCountry() {
      const data = {
        name: this.name
      }
      api.regions.createCountry(data)
        .then(res => {
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.fetchCountryList()
          this.name.uz = ''
          this.name.ru = ''
        })
    },

    deleteCountry(id) {
      api.regions.deleteCountry(id)
        .then(res => {
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.fetchCountryList()
        })
    },

    updateCountry(id, data) {
      const body = {
        name: data.name
      }
      api.regions.updateCountry(id, body)
        .then(res => {
          this.name.ru = res.data.name.ru
          this.name.uz = res.data.name.uz
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.fetchCountryList()
        })
    },

    // checkStatus(value) {
    //   if (value) return true
    //   return false
    // },

    changeVariant(value) {
      if (value.is_deleted) return 'danger'
      return 'success'
    },

    checkLocales(value) {
      if (localStorage.lang === 'ru') {
        return value.ru
      }
      return value.uz
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },

    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of modals/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="scss">

[dir] .dropdown-item {
  padding: 0;
}

</style>
